var selectMonth = new Array('Null','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec');
var dateNow = new Date();
window._gaq = window._gaq || [];

function populateYmSelectBoxes()
{
	var allYmSelects = document.getElementsByClassName("ymSelectBox");
	for (var counter = 0; counter < allYmSelects.length; counter++)
	{
		var month = dateNow.getMonth() + 1;
		var year = dateNow.getFullYear();
		// years, month
		for(var i=1; i<=19; i++)
		{
			var newOption = document.createElement("option");
			newOption.value = year + "-" + month;
			newOption.appendChild(document.createTextNode(selectMonth[month] + "-" + year));
			allYmSelects[counter].appendChild(newOption);
			if ( month >= 12 )
			{
				year++;
				month=1;
			}
			else
			{
				month++;
			}
		}
		allYmSelects[counter].value = dateNow.getFullYear() + '-' + ( parseInt(dateNow.getMonth() + 1) );
	}
}

function findParentIdInDom( elem, targetNodeName )
{
	if ( elem.parentNode.nodeName == targetNodeName )
	{
		if( elem.parentNode.id.length > 0 )
			return elem.parentNode.id;
		else
			return null;
	}
	else if( elem.parentNode.nodeName == 'BODY' || elem.parentNode.nodeName == 'HTML' )
		return null;
	else
		return findParentIdInDom( elem.parentNode, targetNodeName );
}

function populateNightsSelectBoxes()
{
	var allNightsSelects = document.getElementsByClassName( "nightsSelectBox" );
	
	for (var counter = 0; counter < allNightsSelects.length; counter++)
	{
		var formID = findParentIdInDom( allNightsSelects[counter], 'FORM' );
		var nightsDataAttribute = 0;
		if ( formID != null )
			nightsDataAttribute = document.forms[formID].dataset.maxStay;
		if ( nightsDataAttribute === undefined )
			nightsDataAttribute = 31;

		for (var i = 1; i <= nightsDataAttribute; i++)
		{
			var newOption = document.createElement( "option" );
			newOption.value = i;
			newOption.appendChild(document.createTextNode(i));
			allNightsSelects[counter].appendChild(newOption);
		}
		allNightsSelects[counter].value = 1;
	}
}
function populateDaysSelectBoxes()
{
	var allDaysSelects = document.getElementsByClassName("daysSelectBox");
	for (var counter = 0; counter < allDaysSelects.length; counter++)
	{
		for (var i = 1; i <= 31; i++)
		{
			var newOption = document.createElement( "option" );
			newOption.value = i;
			newOption.appendChild(document.createTextNode(i));
			allDaysSelects[counter].appendChild(newOption);
		}
		allDaysSelects[counter].value = dateNow.getDate();
	}
}

populateDaysSelectBoxes();
populateNightsSelectBoxes();
populateYmSelectBoxes();

//submit func
function submitAvvioQuickBook(formID)
{
	var promoElem = document.forms[formID].getElementsByClassName('promotionCode')[0];

	if ( typeof(promoElem) != 'undefined' && promoElem.value != null && promoElem.value != '' )
		document.forms[formID].getElementsByClassName('av')[0].value = "promo";
	else
		document.forms[formID].getElementsByClassName('av')[0].value = "search";

	yearMonth = document.forms[formID].getElementsByClassName('ymSelectBox')[0].value;
	day  = document.forms[formID].getElementsByClassName('daysSelectBox')[0].value;
	document.forms[formID].getElementsByClassName('checkin')[0].value = yearMonth + '-' + day;
	_gaq.push(['_linkByPost', document.forms[formID]]);
	document.forms[formID].submit();
}
function daysBetween( date1, date2 )
{
	var oneDay = 1000 * 60 * 60 * 24;
	var date1Time = date1.getTime();
	var date2Time = date2.getTime();
	var differenceTime = Math.abs( date1Time - date2Time );
	return Math.round( differenceTime / oneDay );
}
function getRemainingDays() 
{
	var date = new Date();
	var time = new Date( date.getTime() );
	time.setMonth( date.getMonth() + 1 );
	time.setDate( 0 );
	var days = time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
	return days;
}


///

var libFlag = 0;
if ( window.jQuery ) 
{
	libFlag = 1;
	if ( window.jQuery.ui )
	{
		var jqy = jQuery.noConflict();
		(function ( jqy )
		{
			jqy.fn.initCalendar = function(options)
			{
				var settings = jqy.extend({
					qbID: "avvioQuickBookWithPromo",
					qbHas2Dates: false
				}, options );

				return this.each( function() 
				{
					var dp1 = jqy ('#' + settings.qbID + ' .cal');
					var dp2 = jqy ('#' + settings.qbID + ' .cal2');
					jqy(this).show();

					var df = dp1.attr('data-date-format') ? dp1.attr('data-date-format') : 'dd MM yy';

					var propertyMaxDate = parseInt (jqy('#' + settings.qbID).attr('data-max-stay') ? jqy('#' + settings.qbID).attr('data-max-stay') : '30' );
					var maxDateAttr = jqy('#' + settings.qbID).attr('data-max-date') ? jqy('#' + settings.qbID).attr('data-max-date') : '18M';
					var maxDate = '+' + maxDateAttr + ' ' + getRemainingDays() + 'd';

					dp1.datepicker(
					{
						minDate : "0",
						changeMonth : true,
						numberOfMonths : 1,
						dateFormat : df,
						maxDate : maxDate,
						onSelect : function(selectedDate) 
						{
							var dateSelected = dp1.datepicker('getDate');
							var dayNumber = dateSelected.getDate();
							var monthNumber = dateSelected.getMonth() + 1;
							var yearNumber = dateSelected.getFullYear();
							document.forms[settings.qbID].getElementsByClassName('daysSelectBox')[0].value = dayNumber;
							document.forms[settings.qbID].getElementsByClassName('ymSelectBox')[0].value = yearNumber + '-' + monthNumber;

							if ( settings.qbHas2Dates )
							{
								var dateSelected2 = dp2.datepicker('getDate');
								if (dateSelected2.getTime() <= dateSelected.getTime())
								{
									var date2 = dp1.datepicker('getDate', '+1d');
									date2.setDate(date2.getDate()+1);
									dp2.datepicker( 'option', 'maxDate', maxDate);
									dp2.datepicker( 'setDate', date2 );
									dateSelected2 = dp2.datepicker('getDate');
								}
								else
								{
									if ( daysBetween(dateSelected,dateSelected2) > propertyMaxDate)
									{
										var date2 = dp1.datepicker('getDate', '+1d');
										date2.setDate(date2.getDate()+1);
										dp2.datepicker( 'setDate', date2 );
									}
								}

								var maxDateChange = dp1.datepicker('getDate');
								maxDateChange.setDate( maxDateChange.getDate() + propertyMaxDate );
								dp2.datepicker( 'option', 'maxDate', maxDateChange);
								
								var nights = daysBetween( dp1.datepicker('getDate'),dp2.datepicker('getDate') );
								document.forms[settings.qbID].getElementsByClassName('nightsSelectBox')[0].value = nights;
							}
						}
					});
					dp1.datepicker('setDate', dateNow);

					if ( settings.qbHas2Dates )
					{
						if( dp2.attr('data-date-format') ) df = dp2.attr('data-date-format');
						dp2.datepicker({
							defaultDate: "+1d",
							minDate: "+1",
							changeMonth: true,
							numberOfMonths: 1,
							dateFormat: df,
							maxDate : '+' + propertyMaxDate + 'd',
							onSelect: function( selectedDate )
							{
								var dateSelected = dp1.datepicker('getDate');
								var dateSelected2 = dp2.datepicker('getDate');
								if (dateSelected2.getTime() <= dateSelected.getTime())
								{
									var date2 = dp2.datepicker('getDate', '-1d');
									date2.setDate(date2.getDate()-1); 
									dp1.datepicker( 'setDate', date2 );
									dateSelected = dp1.datepicker('getDate');
								}
								var maxDateChange = dp1.datepicker('getDate');
								maxDateChange.setDate( maxDateChange.getDate() + propertyMaxDate );
								dp2.datepicker( 'option', 'maxDate', maxDateChange );
								var nights = daysBetween( dp1.datepicker('getDate'),dp2.datepicker('getDate') );
								document.forms[settings.qbID].getElementsByClassName('nightsSelectBox')[0].value = nights;
								
								dateSelected = dp1.datepicker('getDate');
								var dayNumber = dateSelected.getDate();
								var monthNumber = dateSelected.getMonth() + 1;
								var yearNumber = dateSelected.getFullYear();
								document.forms[settings.qbID].getElementsByClassName('daysSelectBox')[0].value = dayNumber;
								document.forms[settings.qbID].getElementsByClassName('ymSelectBox')[0].value = yearNumber + '-' + monthNumber;
							}
						});
						dp2.datepicker('setDate', dateNow);
					}
				});
			};
		}(jQuery));
	}
	else
	{
		libFlag = 0;
	}
}

if( !libFlag )
{
	var x = document.getElementsByClassName("hideInput");var i;
	for (i = 0; i < x.length; i++)
	{
		x[i].style.display = "block";
	}
}

//

if ( window.jQuery )
{
	jQuery( function( jqy )
	{
		jqy('.calendarWithOccupancy').initCalendar({
			qbID : "avvioQuickBookWithOccupancy"
		});
	});
}