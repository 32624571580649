Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}

$ = jQuery;

//$.fancybox.defaults.thumbs.autoStart = true;
$.fancybox.defaults.infobar = true;
$.fancybox.defaults.iframe.preload = false;
$.fancybox.defaults.buttons = ['zoom', 'slideShow', 'fullScreen', 'thumbs', 'close'];


var perPage = 6;

function showPage(page) {
	if(selectedCategory) {
		var $els = $('[data-entries-appearance="filterPaginations"] > a[data-category*="'+selectedCategory+'"]');
	} else {
		var $els = $('[data-entries-appearance="filterPaginations"] > a');
	}
	$els.hide(0);
	var s = (page - 1) * perPage;
	var e = page * perPage;
	var $show = $els.slice(s, e);
	$show.fadeIn(250);
}

function filterPagination() {
	if(selectedCategory) {
		var $els = $('[data-entries-appearance="filterPaginations"] > a[data-category*="'+selectedCategory+'"]');
	} else {
		var $els = $('[data-entries-appearance="filterPaginations"] > a');
	}
	var len = $els.length;
	//console.log(len);
	//if(len * perPage / perPage > 1) {
		//console.log(len/perPage);
	//}

	var $list = $('<div class="t-pagination">');

	var max = Math.ceil(len/ perPage);
	
	if(max > 1) {
		for(var x = 1; x <= max; x++) {
			var $pageLink = $('<a data-page='+x+'>'+x+'</a>');			
			$pageLink.on('click', function(){
				$('[data-page]').removeClass('active');
				$(this).addClass('active');
				showPage($(this).data('page'));
			})
			$list.append($pageLink);

		}
	}



	$('[data-pagination]').html('');
	$('[data-pagination]').append($list);

	$('[data-page=1]').triggerHandler('click');

}

var selectedCategory = null;

$(function(){

	if($('[data-entries-appearance]').length) {

		var appearance = $('[data-entries-appearance]').data('entries-appearance');

		if(appearance == 'loadMore') {

			var $entries = $('#entry-list>*:gt(2)');
			$entries.hide(0);

			var $button = $('<div class="m-button-group m-button-group--inverse m-button-group--centered"><a class="m-button m-button--inline">View all</a></div>');
			$('#entry-list').after($button);

			$button.on('click', function(){
				$('#entry-list>*').fadeIn(250);             
				$(this).remove();
			}) 


		} else {

			$('[data-search-entries]').on('change', function(){
				var val = $(this).val();
				selectedCategory = val;
				if(val) {
					$('[data-category]').hide(0);
					$('[data-category*="'+ val +'"]').fadeIn(200);
				} else {
					$('[data-category]').fadeIn(200);
				}

				filterPagination();
				//showPage(1);
				/*filterPagination({
					cat: 
				});*/
			});

			$('[data-search-entries]').triggerHandler('change');

			filterPagination();

		}

	};


	/**
	 * Enable the header booking button
	 */


	 $('[data-book-now-header]').on('click', e => {
	 	//if($(window).width() > 640) {
		 	$('[data-book-now-box]').fadeToggle(500);
		 	e.preventDefault();
		 	return true;
		 //}
	 });


	$('table').stacktable();

	var $loader = $('[data-loader]');

	if(getUrlParameter('resetanim')) {
		eraseCookie('animloaded');
	}

	if(!readCookie('animloaded')) {
		var $loader = $('<div class="t-loader" data-loader></div>');
		$('body').prepend($loader);
		window.scrollTo(0, 0);
		setTimeout(function(){
			$loader.addClass('active');			
			createCookie('animloaded', 1);
			setTimeout(function(){
				$loader.addClass('loading');
				setTimeout(function(){
					$loader.addClass('loaded');
				}, 750);
			}, 750);
		}, 1);
	}

	
	/**
	 * "Fade in content as you scroll" functionality
	 */

	$('.l-content > .t-block').each(function(){
		$('> div', this).css('opacity', 0);
		var $el = $('> div', this);
		var loaded = false;
		$(window).on('scroll', function(){
			var t = $(window).scrollTop() + $(window).height();
			if($el.offset()) {
				var o = $el.offset().top;
				if((t > o) && !loaded) {
					loaded = true;
					$('.h--block').each(function(){
						$(this).css('top', '30px');
						$(this).animate({
							top: '0px'
						}, {
							duration: 1900
						});
					});
					//console.log($el.css('opacity'));
					if($el.css('opacity') == 0) {
						$el.animate({
							opacity: 1
						}, {
							duration: 1500
						});
					}
				}
			}
		});
	});

	/**
	 * The main header nav toggle button
	 */

	$('#headertoggle').on('change', function(){

		var on = $(this).is(':checked');
		$white = $('.l-container > *:not(.l-header), .t-logo--header, .l-header__cta')
		if(on) {
			/*$white.animate({
				opacity: 0
			}, {
				duration: 500
			});
			$white.css('pointer-events', 'none');*/
		} else {
			/*$white.animate({
				opacity: 1
			}, {
				duration: 500
			});
			$white.css('pointer-events', 'all');*/
		}
	});


	$(document).on('click', function(e) {
		if($('#headertoggle').is(':checked')) {

			var $target = $(e.originalEvent.target);

			if(!$target.parents('.l-header__nav').length) {
				$('#headertoggle').click();
			}

		}
		return true;
	});


	$('[data-ratio]').each(function(){
		var $this = $(this);
		$(window).on('resize', function(){
			$this.height($this.width() / $this.data('ratio'));
		})
	});

	$(window).trigger('resize');



	$('[data-select-tab]').click(function(){
		var id = $(this).data('select-tab');
		$('.m-tabs__tabs .m-tabs__tab').removeClass('m-tabs__tab--active');
		$('#'+id).addClass('m-tabs__tab--active');
		$('.m-tabs__label').removeClass('m-tabs__label--active');
		$(this).addClass('m-tabs__label--active');
	});

	var disable = function() {
		return true;
	}


	/**
	 * Initialise various slick slider elements
	 */

	

	$('[data-slick]').each(function(e){

		$(this).slick({
			prevArrow: '[data-slick-prev]',
			nextArrow: '[data-slick-next]',
		});
		$(this).on('afterChange', function(s, c, i, n){
			
			var title = $('[data-title]', '.slick-current').data('title');
			var caption = $('[data-caption]', '.slick-current').data('caption');
			console.log(title);
			$('[data-slide-title]').html(title);
			$('[data-slide-caption]').html(caption);
			$('[data-slide-count]').html((i + 1).pad(2));
		});
	});

	$('[data-slick-carousel]').each(function(e){
		var id = $(this).data('slick-carousel');
		$(this).slick({
			prevArrow: '[data-slick-prev='+id+']',
			nextArrow: '[data-slick-next='+id+']',  
  			slidesToShow: 4,
  			slidesToScroll: 1,
  			swipeToSlide: true,
  			responsive: [
			    {
			      breakpoint: 640,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1,
			        infinite: true,
			        dots: true
			      }
			    }
			],
  			dots: true,
  			appendDots: '[data-carousel-dots='+id+']'
		});
		$(this).on('afterChange', function(s, c, i, n){

		});
	});



	$('[data-slick-page-scroller]').each(function(e){
		var id = $(this).data('slick-page-scroller');
		
		$(this).on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
			var $slickStatus = $('[data-slick-counter]');
    		var i = (currentSlide ? currentSlide : 0) + 1;
    		$slickStatus.text(i + '/' + slick.slideCount);
		});

		$(this).slick({
			prevArrow: '[data-slick-prev='+id+']',
			nextArrow: '[data-slick-next='+id+']',  
  			slidesToShow: 3,
  			slidesToScroll: 1,
  			swipeToSlide: true,
  			responsive: [
			    {
			      breakpoint: 640,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1,
			        infinite: true,
			        dots: false
			      }
			    }
			],
  			//dots: true,
  			//appendDots: '.m-carousel__dots'
		});




	});


	$('[data-slick-testimonials-scroller]').each(function(e){
		var id = $(this).data('slick-testimonials-scroller');
		$(this).slick({
			prevArrow: '[data-slick-prev='+id+']',
			nextArrow: '[data-slick-next='+id+']',  
  			slidesToShow: 1,
  			swipeToSlide: true,
  			slidesToScroll: 1,
  			//dots: true,
  			//appendDots: '.m-carousel__dots'
		});
		$(this).on('afterChange', function(s, c, i, n){

		});
	});

	$('[data-slick-hero-scroller]').each(function(e){
		var id = $(this).data('slick-hero-scroller');
		console.log(id);
		$(this).slick({
			prevArrow: '[data-slick-prev='+id+']',
			nextArrow: '[data-slick-next='+id+']',
  			slidesToShow: 1,
  			swipeToSlide: true,
  			slidesToScroll: 1,
  			dots: true,
  			appendDots: '.t-leader-hero__banner-dots'
		});
		$(this).on('afterChange', function(s, c, i, n){

		});
	});	

	$('[data-slick-image-pair-scroller]').each(function(e){
		var id = $(this).data('slick-image-pair-scroller');
		$(this).slick({
			prevArrow: '[data-slick-prev='+id+']',
			nextArrow: '[data-slick-next='+id+']',			
  			slidesToShow: 1,
  			swipeToSlide: true,
  			slidesToScroll: 1,
  			//dots: true,
  			//appendDots: '.t-leader-hero__banner-dots'
		});
		$(this).on('afterChange', function(s, c, i, n){

		});
	});		



	$(window).triggerHandler('scroll');



	$('.m-image-link').on('mouseover', function(){
		$('.m-button', $(this)).addClass('hover');
	});

	$('.m-image-link').on('mouseout', function(){
		$('.m-button', $(this)).removeClass('hover');
	});


	/**
	 * "Room details" accordion functionality
	 */

	$('[data-accordion]').each(function(){
		$('.m-accordion__segment:not(:first-child) [data-copy]').hide(0);
		$('.m-accordion__segment:first-child [data-heading]').addClass('active');
		var $this = $(this);
		var $toggle = $('[data-heading]', $this);
		$toggle.on('click', function(){

			if($(this).hasClass('active')) {
				return;
			}

			var $next = $(this).next();
			$('[data-copy]').not($next).slideUp();
			$('[data-heading]', $this).removeClass('active');
			$(this).addClass('active');
			$next.slideToggle();
		})
	});


	$(window).on('scroll', function(){
		var scroll = $(window).scrollTop();
		if(scroll > 5) {
			$('.t-header').addClass('scrolled');
		} else {
			$('.t-header').removeClass('scrolled');
		}
	});



	/**
	 * Initialise the hero video functionality
	 */

	var $videos = $('.hero-video');
	$videos.each(function() {
		var $source = $('source', $(this));
		var hd = $(this).data('hd');
		var sd = $(this).data('sd');
		var video = $(this)[0]; // fetch DOM element
		if($(window).width() > 640) {
			$source.attr('src', hd);
		} else {
			$source.attr('src', sd);
		}
		video.load();
		video.play();
	});
//var source = document.getElementById('source');

//source.setAttribute('src', 'http://www.tools4movies.com/trailers/1012/Kill%20Bill%20Vol.3.mp4');




});



// Helpers
function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};


// run immediately
//window.addEventListener('DOMContentLoaded', (event) => {
	var x = document.querySelectorAll(".t-block > div");
	var i;
	for (i = 0; i < x.length; i++) {
	  x[i].style.opacity = 0;
	} 
//});